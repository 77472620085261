var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projectChart" },
    [
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-card",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", [_vm._v("足底压力图")]),
                      _c(
                        "div",
                        { staticStyle: { float: "right" } },
                        [
                          _c("span", { staticStyle: { "font-size": "12px" } }, [
                            _vm._v("镜像")
                          ]),
                          _c("el-switch", {
                            on: { change: _vm.roateimage },
                            model: {
                              value: _vm.footPressure.Invert,
                              callback: function($$v) {
                                _vm.$set(_vm.footPressure, "Invert", $$v)
                              },
                              expression: "footPressure.Invert"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-carousel",
                    { attrs: { height: "600px" } },
                    _vm._l(_vm.images, function(url) {
                      return _c(
                        "el-carousel-item",
                        { key: url },
                        [
                          _c("el-image", {
                            key: url,
                            attrs: { src: url, fit: "fill" }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-card", { staticStyle: { width: "100%" } }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("足底扫描")])]
                ),
                _c("div", { staticStyle: { height: "600px" } })
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }