<template>
    <div class="projectChart">
      <el-row style="margin-top: 20px" :gutter="20">
        <el-col :span="12">
          <el-card style="width: 100%">
            <div slot="header" class="clearfix">
              <span>足底压力图</span>
              <div style="float: right">
                <span style="font-size: 12px">镜像</span
                ><el-switch
                  @change="roateimage"
                  v-model="footPressure.Invert"
                ></el-switch>
              </div>
            </div>
            <el-carousel height="600px">
              <el-carousel-item v-for="url in images" :key="url">
                <el-image :key="url" :src="url" fit="fill"></el-image>
              </el-carousel-item>
            </el-carousel>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card style="width: 100%">
            <div slot="header" class="clearfix">
              <span>足底扫描</span>
            </div>
            <div style="height: 600px"></div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </template>
  <script>
  import echart from "@/components/echart";
  import {  invertFootPressureImages } from "@/api/check/check.js";
  export default {
    components: { echart },
    props: {
      footPressure: "",
      invert: false,
      checkKey: "",
    },
    watch: {
      checkKey: {
        handler(v) {
          this.loadFootPressure();
        },
        deep: true,
        immediate: true,
      },
      footPressure: {
        handler(v) {
          this.loadFootPressure();
        },
        deep: true,
        immediate: true,
      },
    },
    data() {
      return {
        images: [],
      };
    },
    methods: {
      roateimage() {
        let parma = {
          checkKey: this.checkKey,
          footPressureKey: this.footPressure.Key,
          invert: this.footPressure.Invert,
        };
        invertFootPressureImages(parma).then((res) => {
          this.loadFootPressure();
        });
      },
      loadFootPressure() {
        this.images = [];
        let refresh = "?" + new Date().getTime();
        if (this.footPressure && this.checkKey) {
          this.images = [];
          this.images.push(
            this.baseUrl +
              "report/" +
              this.checkKey +
              "/footPressure/static.png" +
              refresh
          );
          this.images.push(
            this.baseUrl +
              "report/" +
              this.checkKey +
              "/footPressure/posture.png" +
              refresh
          );
          this.images.push(
            this.baseUrl +
              "report/" +
              this.checkKey +
              "/footPressure/dynamic.png" +
              refresh
          );
        }
      },
    },
    mounted() {
    },
  };
  </script>
  <style rel="stylesheet/scss" lang="scss" scoped>
  .projectChart {
    width: 100%;
    .images {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      .item {
      }
    }
  }
  </style>